import React     from 'react';
import PropTypes from 'prop-types';
import Img       from 'gatsby-image';

import SEO                 from '@interness/web-core/src/components/modules/SEO/SEO';
import Spacer              from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper             from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading             from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction        from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImage         from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Logo                from '@interness/web-core/src/components/media/Logo/Logo';
import BrandsSubnavigation
                           from '@interness/brands-addon/src/components/modules/BrandsSubnavigation/BrandsSubnavigation';

function PageLayout(props) {
  const { page, translations, seoTitle, showCallToAction, headerImage, showSubnavigation, showHeading } = props;
  return (
    <>
      <SEO title={seoTitle}
           description={translations.seo_description ? translations.seo_description : null}
           lang={translations.language}/>
      {headerImage && <HeaderImage>
        <Img fluid={headerImage} alt={translations.display_name} loading="eager"/>
      </HeaderImage>}
      {showSubnavigation && <BrandsSubnavigation type={props.type}/>}
      <Wrapper>
        {showHeading &&
          <>
            <Spacer/>
            <Heading text={seoTitle}>{page.logo
              ? <div style={{ maxHeight: '80px', maxWidth: '240px', margin: 'auto' }}><Logo title={seoTitle} svgWidth="240px" svgHeight='80px' logo={page.logo.localFile}/>
              </div>
              : <>{seoTitle}</>
            }</Heading>
            <Spacer/>
          </>
        }
        {props.children}
        {showCallToAction &&
          <>
            <Spacer/>
            <div style={{ textAlign: 'center' }}>
              <CallToAction/>
            </div>
          </>}
        <Spacer/>
      </Wrapper>
    </>
  )
}

PageLayout.propTypes = {
  page: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  submenu: PropTypes.array,
  showCallToAction: PropTypes.bool,
  showHeading: PropTypes.bool
};

PageLayout.defaultProps = {
  submenu: [],
  showCallToAction: false,
  showHeading: true,
};

export default PageLayout;
